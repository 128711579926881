// This function is to convert the new API response to matched the currency v-data-table data
function formatBannerPerformancesForTable(responseData) {
	const bannerData = {
		total: responseData?.bannerInfo?.length,
		totalPages: 0,
		data: []
	}
	responseData.bannerInfo.forEach((el) => {
		const id = el.id

		const customData = {
			...el
		}
		const findMatchedBannerStats = responseData.bannerStats.find(el => el.id === id)
		if (findMatchedBannerStats) {
			delete findMatchedBannerStats.id;
			delete findMatchedBannerStats._id;
			Object.assign(customData, findMatchedBannerStats)
		}
		const findMatchedBannerCost = responseData.bannerCost.find(el => el.banner_id === id)
		if (findMatchedBannerCost) {
			delete findMatchedBannerCost.banner_id
			Object.assign(customData, findMatchedBannerCost)
		}
		const findMatchedBannerClick = responseData.totalClicks.find(el => el.banner_id === id)
		if (findMatchedBannerClick) {
			delete findMatchedBannerClick.banner_id
			Object.assign(customData, findMatchedBannerClick)
		}
		const findMatchedIpView = responseData.ipView.find(el => el.banner_id === id)
		if (findMatchedIpView) {
			delete findMatchedIpView.banner_id
			Object.assign(customData, findMatchedIpView)
		}
		const findMatchedUniqueClicks = responseData.uniqueClicks.find(el => el._id === id)
		if (findMatchedUniqueClicks) {
			delete findMatchedUniqueClicks._id
			Object.assign(customData, findMatchedUniqueClicks)
		}
		const findMatchedUniqueIpViews = responseData.uniqueIpViews.find(el => el._id === id)
		if (findMatchedUniqueIpViews) {
			delete findMatchedUniqueIpViews._id
			Object.assign(customData, findMatchedUniqueIpViews)
		}

		bannerData.data.push(customData)
	})
	bannerData.data.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
	bannerData.data.forEach((banner) => {
		if (banner) {
			banner.total_deposit_count = 0
			banner.total_deposit_count += banner.total_direct_player_with_deposit
			banner.total_deposit_count += banner.total_associated_player_with_deposit

			// Add reward claim for data sorting
			banner.reward_claimed = 0
			banner.reward_claimed += parseFloat(banner.direct_total_reward_claimed_amount || '0')
			banner.reward_claimed += parseFloat(banner.associated_total_reward_claimed_amount || '0')


		}
	})
	return bannerData
}

// State
export const state = () => ({
	loading: false,
	banners: {
		total: 0,
		totalPages: 0,
		data: []
	},
	banner: {},
	bannersClicks: [],
	bannerCost: [],
	categories: [],
	bannerPerformances: {
		total: 0,
		totalPages: 0,
		data: []
	},
	players: [],
	countryCode: [],
	bannerPlayer: {},
	bannerStatistic: {},
	clickRecords: {},
	viewRecords: {},
	playerAccountAgeChart: {
		label: [1, 2, 3, 4, 5, 6, 7],
		playerCount: []
	},
	playerChart: {},

	playerFinancial: {
		label: [],
		betWinLoss: [],
		depositAmount: [],
		depositCount: [],
		withdrawalAmount: [],
		withdrawCount: [],
		netCashflow: [],
		turnoverDepositRatio: [],
		turnover: []
	},
	marketingPerformanceParams: {}, // Since, marketing performance page is not fired all the first page visit, I need to store all the filters for temporary

	// cancel token
	bannerCancelToken: {}
})

// Actions
export const actions = {
	async create({ commit }, form) {
		commit('setLoading', true)
		const response = await this.$axios
			.post(`/marketing-tools/banners`, form)
			.then((response) => {
				commit('setLoading', false)
				return true
			})
			.catch(() => {
				commit('setLoading', false)
				return false
			})
		return response
	},

	// Get all banners
	async banners({ commit, state }, data) {
		commit('setBannerCancelToken', { banners: this.$axios.CancelToken.source() })
		if (data.is_expired) delete data.is_active
		return await this.$axios
			.get(`/marketing-tools/banners`, {
				params: data,
				cancelToken: state.bannerCancelToken.banners.token
			})
			.then((response) => {
				commit('setBanners', response.data)
				return true
			}).catch(() => false)
	},

	// Get single banner
	async banner({ commit, state }, id) {
		commit('setBannerCancelToken', { banner: this.$axios.CancelToken.source() })
		commit('setLoading', true)
		return await this.$axios
			.get(`/marketing-tools/banners/${id}`, { cancelToken: state.bannerCancelToken.banner.token })
			.then((response) => {
				commit('setBanner', response.data)
				return true
			}).catch(() => false).finally(() => {
				commit('setLoading', false)
			})
	},



	// Edit banner
	async edit({ commit, dispatch }, { form, id }) {
		try {
			commit('setLoading', true)
			return await this.$axios
				.put(`/marketing-tools/banners/${id}`, form, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then(() => {
					return true
				})
		} catch (error) {
			commit('setLoading', false)
		}
	},
	// Edit banner
	async updateBannerStatus({ commit, dispatch }, { form, id }) {
		try {
			commit('setLoading', true)
			return await this.$axios
				.put(`/marketing-tools/banners/status/${id}`, form)
				.then(() => {
					return true
				})
		} catch (error) {
			commit('setLoading', false)
		}
	},

	// Delete banner
	async delete({ dispatch }, bannerId) {
		try {
			return await this.$axios
				.delete(`/marketing-tools/banners/${bannerId}`)
				.then((response) => {
					return true
				})
		} catch (error) {
		}
	},

	// Delete multi banners
	deleteMultiple({ rootGetters }, array) {
		const bannerIds = array.map(o => o.id)
		this.$axios
			.delete(`/banners`, { data: { banner_ids: bannerIds } })
			.then(() => {
			}).catch((e) =>
				this.$toast.error(e)
			)
	},

	// Remove banner cost
	async bannerCostRemove({ commit, rootGetters }, payload) {
		return await this.$axios.delete('/banner-cost/' + payload.costId).then(response => {
			commit('removeBannerCost', payload.index)
			return true
		})
	},

	// Get all banners
	async bannerPerformances({ commit, state }, data) {
		commit('setBannerCancelToken', { bannerPerformances: this.$axios.CancelToken.source() })

		const params = JSON.parse(JSON.stringify(data))
		params.start_date = data?.start_date?.split(" ")[0] + " 00:00:00"
		params.end_date = data?.end_date?.split(" ")[0] + " 23:59:59"

		return await this.$axios
			.get(`/statistics/banner-performance`, {
				params,
				cancelToken: state.bannerCancelToken.bannerPerformances.token
			})
			.then((response) => {
				const responseData = response.data;

				const bannerData = formatBannerPerformancesForTable(responseData)

				// Make the data format to be same as previous data
				commit('bannerPerformances', bannerData)
				return true
			}).catch(() => false)
	},
	async bannerCategories({ commit, state }) {
		commit('setBannerCancelToken', { bannerCategories: this.$axios.CancelToken.source() })
		commit('setLoading', true)
		const response = await this.$axios
			.get(`/marketing-tools/banner-category`, {
				cancelToken: state.bannerCancelToken.bannerCategories.token
			})
			.then((response) => {
				commit('bannerCategories', response.data)
				return true
			}).catch(() => false)
		commit('setLoading', false)
		return response
	},
	async createCategories({ commit, dispatch }, data) {
		commit('setLoading', true)
		return await this.$axios
			.post(`/marketing-tools/banner-category`, data)
			.then(() => {
				dispatch('bannerCategories')
				commit('setLoading', false)
				return true
			}).catch(() => { commit('setLoading', false); return false })
	},
	async updateCategories({ commit, dispatch }, form) {
		commit('setLoading', true)
		return await this.$axios
			.put(`/marketing-tools/banner-category/${form.id}`, form.body)
			.then(() => {
				dispatch('bannerCategories')
				commit('setLoading', false);
				return true
			}).catch(() => { commit('setLoading', false); return false })
	},
	async searchCountryCode({ commit }, params) {
		const response = await this.$axios.get(`/countries/${params}`)
			.then((response) => {
				commit('countryCode', response.data)
				return response.data
			}).catch(() => {
				return []
			})
		return response
	},
	async getBannerStatistic({ commit, state }, form) {
		commit('setBannerCancelToken', { getBannerStatistic: this.$axios.CancelToken.source() })
		commit('setLoading', true);
		const params = { ...form }
		if (form.start_date && form.end_date) {
			params.start_date = this.$dayjs(form.start_date).format("YYYY-MM-DD") + " 00:00:00";
			params.end_date = this.$dayjs(form.end_date).format("YYYY-MM-DD") + " 23:59:59";
		}
		const id = params.id;
		delete params.id
		await this.$axios.get(`/statistics/banner-performance/stats/${id}`, {
			params,
			cancelToken: state.bannerCancelToken.getBannerStatistic.token
		})
			.then((response) => {
				commit('setBannerStatistic', response.data)
				commit('setLoading', false)
			})
			.catch(() => { commit('setLoading', false); return false })
	},
	async getBannerPlayer({ commit, state }, form) {
		commit('setBannerCancelToken', { getBannerPlayer: this.$axios.CancelToken.source() })
		commit('setLoading', true);
		const params = { ...form }
		if (form.start_date && form.end_date) {
			params.start_date = this.$dayjs(form.start_date).format("YYYY-MM-DD") + " 00:00:00";
			params.end_date = this.$dayjs(form.end_date).format("YYYY-MM-DD") + " 23:59:59";
		}
		const id = params.id;
		delete params.id
		await this.$axios.get(`/statistics/banner-performance/players/${id}`, {
			params,
			cancelToken: state.bannerCancelToken.getBannerPlayer.token
		})
			.then((response) => {
				commit('setBannerPlayer', response.data)
				commit('setLoading', false)
			})
			.catch(() => { commit('setLoading', false); return false })
	},
	async getBannerPlayerReport({ commit, state }, form) {
		commit('setBannerCancelToken', { getBannerPlayerReport: this.$axios.CancelToken.source() })
		const params = { ...form }
		const id = params.id;
		delete params.id
		return await this.$axios.get(`/statistics/banner-performance/players/${id}`, {
			params,
			cancelToken: state.bannerCancelToken.getBannerPlayerReport.token
		})
			.then((response) => {
				return response.data
			})
			.catch(() => { return false })
	},
	// Get clicks
	async clickRecords({ commit, state }, params) {
		const data = JSON.parse(JSON.stringify(params))

		// Of search type is source name, search will conflict with backend validations
		if (data.search_column === 'source_name') {
			delete data.search
		}
		if (data.search_column === 'source_name' && !data.banner_id) {
			delete data.search_column
		}
		// Check if Column Search, but no searching data
		if (data.search_column && data.search_column !== 'source_name' && !data.search) {
			delete data.search_column
		}
		if ((data.search && !data.search_column)) {
			delete data.search_column
			delete data.search
		};

		// Check if table is loading, then cancel it
		if (state.bannerCancelToken.clickRecords) {
			state.bannerCancelToken.clickRecords.cancel('')
		}
		commit('setBannerCancelToken', { clickRecords: this.$axios.CancelToken.source() })
		try {
			return await this.$axios
				.get(`/statistics/banner-performance/clicks`, {
					params: data,
					cancelToken: state.bannerCancelToken.clickRecords.token
				})
				.then((response) => {
					commit('clickRecords', response.data)
					return true
				})
		} catch (error) {
			return false
		}

	},
	// Get clicks
	async viewRecords({ commit, state }, params) {
		const data = JSON.parse(JSON.stringify(params))
		// Of search type is source name, search will conflict with backend validations
		if (data.search_column === 'source_name') {
			delete data.search
		}
		if (data.search_column === 'source_name' && !data.banner_id) {
			delete data.search_column
		}
		// Check if Column Search, but no searching data
		if (data.search_column && data.search_column !== 'source_name' && !data.search) {
			delete data.search_column
		}
		if (data.search && !data.search_column) {
			delete data.search_column
			delete data.search
		}


		// Check if table is loading, then cancel it
		if (state.bannerCancelToken.viewRecords) {
			state.bannerCancelToken.viewRecords.cancel('')
		}
		commit('setBannerCancelToken', { viewRecords: this.$axios.CancelToken.source() })
		try {
			return await this.$axios
				.get(`/statistics/banner-performance/views`, {
					params: data,
					cancelToken: state.bannerCancelToken.viewRecords.token
				})
				.then((response) => {
					commit('viewRecords', response.data)
					return true
				})
		} catch (error) {
			return false
		}
	},
	async playerAccountAges({ commit, state }, query) {
		commit('setBannerCancelToken', { playerAccountAges: this.$axios.CancelToken.source() })
		const bannerId = query.bannerId
		const params = JSON.parse(JSON.stringify(query))
		delete params.bannerId
		await this.$axios.get(`/statistics/banner-performance/account-age-chart/${bannerId}`, { params, })
			.then((response) => {
				commit('setPlayerAccountAgeChart', response.data)
			}).catch(() => false)
	},
	async playerChart({ commit, state }, { id, params }) {
		commit('setBannerCancelToken', { playerChart: this.$axios.CancelToken.source() })
		await this.$axios.get(`/statistics/banner-performance/player-register-chart/${id}`, { params, })
			.then((response) => {
				commit('setPlayerChart', response.data)
			}).catch(() => false)
	},
	async playerFinancialChart({ commit, state }, { id, params }) {
		commit('setBannerCancelToken', { playerFinancialChart: this.$axios.CancelToken.source() })
		await this.$axios.get(`/statistics/banner-performance/player-transaction-chart/${id}`, { params, })
			.then((response) => {
				commit('setPlayerFinancial', response.data)
			}).catch(() => false)
	}
}

// Mutations
export const mutations = {
	setLoading(state, type) {
		state.loading = type
	},
	setBanners(state, banners) {
		state.banners = banners
	},
	setBanner(state, banner) {
		state.banner = banner
	},
	setBannersClicks(state, clicks) {
		state.bannersClicks = clicks
	},
	removeBannerCost(state, index) {
		state.bannerCost.splice(index, 1)
	},
	bannerPerformances(state, banners) {
		state.bannerPerformances = banners
	},
	bannerCategories(state, data) {
		state.categories = data
	},
	setPlayer(state, data) {
		state.players = data
	},
	countryCode(state, data) {
		state.countryCode = data
	},
	setBannerStatistic(state, data) {
		state.bannerStatistic = data
	},
	setBannerPlayer(state, data) {
		state.bannerPlayer = data
	},
	clickRecords(state, data) {
		state.clickRecords = data
	},
	viewRecords(state, data) {
		state.viewRecords = data
	},
	setPlayerAccountAgeChart(state, response) {
		state.playerAccountAgeChart = response
	},
	setPlayerChart(state, response) {
		state.playerChart = response
	},
	setMarketingPerformanceParams(state, data) {
		state.marketingPerformanceParams = data
	},
	setPlayerFinancial(state, response) {
		const data = {
			label: response.label,
			betWinLoss: response.totalBetWinLoss,
			depositAmount: response.totalDepositAmount,
			depositCount: response.depositCount,
			withdrawalAmount: response.totalWithdrawalAmount,
			withdrawCount: response.withdrawalCount,
			netCashflow: response.netCashflow,
			turnoverDepositRatio: response.turnoverDepositRatio,
			turnover: response.turnover,
		}
		state.playerFinancial = data
	},
	setBannerCancelToken: (state, data) => {
		const oldData = { ...state.bannerCancelToken }
		state.bannerCancelToken = {
			...oldData, ...data
		}
	}
}