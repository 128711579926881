// State
export const state = () => ({
	loading: false,
	accounts: [],
	account: {},
	qrImage: null,
	banks: [],
	companyBanks: [],
	bankAccount: {},
	selfManagedPaymentGateway: {},
	selfManagedPaymentGatewayStatus: false,
	singleSelfManagedPaymentGateway: {},
	cancelToken: {}
})

// Actions
export const actions = {
	// Get all banks
	banks({ commit }) {
		commit('setLoading', true)
		this.$axios
			.get(`/bank-list`)
			.then((response) => {
				commit('banks', response.data)
			})
			.catch(() => false)
			.finally(() => commit('setLoading', false))
	},
	companyBanks({ commit }) {
		commit('setLoading', true)
		this.$axios
			.get(`/bank-list/company-bank-list`)
			.then((response) => {
				commit('companyBanks', response.data)
			})
			.catch(() => false)
			.finally(() => commit('setLoading', false))
	},

	// Get single account
	async account({ commit }, accountId) {
		commit('setLoading', true)
		return await this.$axios
			.get(`/finances/company-accounts/${accountId}`)
			.then((response) => {
				commit('setAccount', response.data)
				commit('setLoading', false)

				return true
			})
			.catch(() => {
				commit('setLoading', false)
				return false
			})
	},

	// Get all accounts
	async accounts({ commit, state }, params) {
		commit('setCancelToken', { accounts: this.$axios.CancelToken.source() })

		return await this.$axios
			.get(`/finances/company-accounts`, {
				params,
				cancelToken: state.cancelToken.accounts.token
			})
			.then((response) => {
				commit('setAccounts', response.data)
				return true
			})
			.catch(() => false)
	},

	// Edit bank account
	async edit({ commit, rootGetters }, form) {
		try {
			commit('setLoading', true)
			return await this.$axios
				.put(`/finances/company-accounts/${form.id}`, form.data)
				.then(() => {

					commit('setLoading', false)
					this.$router.push('/finance/bank-management/bank-accounts')
					return true
				})
		} catch (error) {
			commit('setLoading', false)
		}
	},

	// Delete bank account
	async delete({ rootGetters }, data) {
		try {
			return await this.$axios
				.delete(`/finances/company-accounts/${data.id}`, { data: data.body })
				.then(() => {
					return true
				})
		} catch (_) {
			// this.$toast.error(error)
		}
	},

	// Getting qr image
	async qrImage({ commit }, accountId) {
		return await this.$axios
			.get(`/finances/company-accounts/qr/${accountId}`)
			.then((response) => {
				commit('setQrImage', response.data)
				return true
			})
			.catch(() => false)
	},
	async createCompanyAccounts({ _ }, data) {
		const response = await this.$axios.post('/finances/company-accounts', data)
			.then((_) => {
				return true;
			}).catch(() => {
				return false
			})
		return response;
	},
	async updateBankAccountStatus({ _ }, data) {
		const response = await this.$axios.put(`/finances/company-accounts/status/${data.id}`, data.body)
			.then((_) => {
				return true;
			}).catch(() => {
				return false
			})
		return response;
	},
	async createSmpg({ commit }, body) {
		commit('setLoading', true)
		const response = await this.$axios.post(`/finances/smpg`, body)
			.then((response) => {
				commit('setLoading', false)
				return true;
			}).catch(() => {
				commit('setLoading', false)
				return false
			})
		return response;
	},
	async checkSmpg({ commit }, body) {
		commit('setLoading', true)
		const response = await this.$axios.post(`/finances/smpg/bcel-login`, body)
			.then((response) => {
				commit('setLoading', false)
				return response.data
			}).catch(() => {
				commit('setLoading', false)
				return false
			})
		return response;
	},
	async verifyTwoStep({ commit }, body) {
		commit('setLoading', true)
		const response = await this.$axios.post(`/finances/smpg/bcel-verify-opt-login`, body)
			.then((response) => {
				commit('setLoading', false)
				return response.data
			}).catch(() => {
				commit('setLoading', false)
				return false
			})
		return response;
	},
	async checkCredentialTest({ commit }, body) {
		commit('setLoading', true)
		const response = await this.$axios.post(`/finances/smpg/credential-test`, body)
			.then((response) => {
				commit('setLoading', false)
				return true;
			}).catch(() => {
				commit('setLoading', false)
				return false
			})
		return response;
	},
	async checkTestEmail({ commit }, body) {
		commit('setLoading', true)
		const response = await this.$axios.post(`/finances/smpg/email-login`, body)
			.then((response) => {
				commit('setLoading', false)
				return true;
			}).catch(() => {
				commit('setLoading', false)
				return false
			})
		return response;
	},
	async smpg({ commit, state }, params) {
		commit('setLoading', true)
		commit('setCancelToken', { smpg: this.$axios.CancelToken.source() })
		const response = await this.$axios.get(`/finances/smpg`, {
			params,
			cancelToken: state.cancelToken.smpg.token
		})
			.then((response) => {
				commit('setSelfManagedPaymentGateway', response.data)
				commit('setLoading', false)
				return true;
			}).catch(() => {
				commit('setLoading', false)
				return false
			})
		return response;
	},
	async singleSmpg({ commit }, id) {
		commit('setLoading', true)
		const response = await this.$axios.get(`/finances/smpg/${id}`)
			.then((response) => {
				commit('setSingleSelfManagedPaymentGateway', response.data)
				commit('setLoading', false)
				return true;
			}).catch(() => {
				commit('setLoading', false)
				return false
			})
		return response;
	},
	async smpgStatus({ commit, state }, params) {
		commit('setSelfManagedPaymentGatewayStatus', false)
		commit('setLoading', true)
		commit('setCancelToken', { smpgStatus: this.$axios.CancelToken.source() })
		const response = await this.$axios.get(`finances/smpg/payment-feature-status`,
			{
				params,
				cancelToken: state.cancelToken.smpgStatus.token
			}
		)
			.then((response) => {
				commit('setSelfManagedPaymentGatewayStatus', response?.data?.is_active)
				commit('setLoading', false)
				return true;
			}).catch(() => {
				commit('setLoading', false)
				return false
			})
		return response;
	},
	async deleteSmpg({ commit }, data) {
		commit('setLoading', true)
		const id = data.id;
		delete data.id;
		const response = await this.$axios.delete(`/finances/smpg/${id}`, { data })
			.then((response) => {
				commit('setLoading', false)
				return true;
			}).catch(() => {
				commit('setLoading', false)
				return false
			})
		return response;
	},
	async updateSmpgStatus({ commit }, data) {
		const response = await this.$axios.put(`/finances/smpg/setting/status`, data)
			.then((response) => {
				commit('setLoading', false)
				return true;
			}).catch(() => {
				commit('setLoading', false)
				return false
			})
		return response;
	},
	async updateSingleSmpgStatus({ commit }, data) {
		const response = await this.$axios.put(`/finances/smpg/status/${data.id}`, data.data)
			.then((response) => {
				commit('setLoading', false)
				return true;
			}).catch(() => {
				commit('setLoading', false)
				return false
			})
		return response;
	},
	async updateSmpg({ commit }, data) {
		commit('setLoading', true)
		const response = await this.$axios.put(`/finances/smpg/${data.id}`, data.data)
			.then((response) => {
				commit('setLoading', false)
				return true;
			}).catch(() => {
				commit('setLoading', false)
				return false
			})
		return response;
	},
	async testSmpg({ _ }, id) {
		const response = await this.$axios.get(`/finances/smpg/test/${id}`)
			.then((response) => {
				return true;
			}).catch(() => {
				return false
			})
		return response;
	},
	async updateThresholdStatus({ _ }, form) {
		const response = await this.$axios.put(`/finances/company-accounts/threshold/reset/${form.id}`, form.body)
			.then((response) => {
				return true;
			})
			.catch(() => {
				return false;
			})
		return response
	},
	async checkBalance({ commit }, id) {
		const response = await this.$axios.get(`/finances/smpg/check-balance/${id}`)
			.then((data) => {
				commit('updateCheckBalance', { balance: data?.data?.balance, id, updateTime: data?.data?.balance_update_time })
				return data.data;
			}).catch(() => {
				return false;
			})
		return response
	}
}

// Mutations
export const mutations = {
	setLoading: (state, type) => {
		state.loading = type
	},
	setAccounts: (state, data) => {
		state.accounts = data
	},
	setAccount: (state, data) => {
		state.account = data
	},
	setQrImage: (state, link) => {
		state.qrImage = link.qrLink
	},
	banks: (state, data) => {
		state.banks = data
	},
	companyBanks: (state, data) => {
		state.companyBanks = data
	},
	setSelfManagedPaymentGateway: (state, data) => {
		state.selfManagedPaymentGateway = data
	},
	setSelfManagedPaymentGatewayStatus: (state, data) => {
		state.selfManagedPaymentGatewayStatus = data
	},
	setSingleSelfManagedPaymentGateway: (state, data) => {
		state.singleSelfManagedPaymentGateway = data
	},
	setCancelToken: (state, data) => {
		const oldData = { ...state.cancelToken }
		state.cancelToken = {
			...oldData, ...data
		}
	},
	updateCheckBalance: (state, { balance, updateTime, id }) => {
		const cloneBalance = JSON.parse(JSON.stringify(state.selfManagedPaymentGateway))
		cloneBalance.data.forEach(el => {
			if (el.id === id) {
				el.balance = balance
				el.balance_update_time = updateTime
			}
		})
		state.selfManagedPaymentGateway = cloneBalance
	}
}