// State
export const state = () => ({
    user: {},
    loading: false,
    googleAuth: null,
})

// Actions
export const actions = {
    async login({ commit, dispatch, rootGetters }, userData) {
        try {
            commit('setLoading', true)

            const response = await this.$axios.post(`auth.login`, userData)
            const responseData = response.data
            const token = response.headers.token
            const cookieExpiration = 60 * 60 * 24 - 60 // 23 hours 59 minutes

            // Check if response contains required user details
            if (
                !responseData.id ||
                !responseData.name ||
                !responseData.phone ||
                !responseData.username
            ) {
                commit('setGoogleAuth', responseData)
                this.$cookie.set('sub_key', token)
                return
            }

            // Store login details
            responseData.token = token
            commit('setLogin', responseData)
            this.$toast.success(
                rootGetters['master/storeTl']('loggedInWelcomeBoard')
            )

            // Store authentication cookies
            this.$cookie.set('token', token, {
                path: '/',
                maxAge: cookieExpiration,
            })
            this.$cookie.set('user', responseData, {
                path: '/',
                maxAge: cookieExpiration,
            })
            this.$cookie.remove('sub_key')

            // Set currency or fetch default
            if (responseData.currency?.trim()) {
                this.$cookie.set('currency', responseData.currency)
            } else {
                await dispatch('settings/currency', {}, { root: true })
            }

            // Store markup values
            ;['markup_1', 'markup_2'].forEach((key) => {
                if (responseData[key] !== undefined) {
                    this.$cookie.set(key, responseData[key], {
                        path: '/',
                        maxAge: cookieExpiration,
                    })
                }
            })

            // Set locale
            this.$cookie.set('locale', responseData.language || 'th-TH')

            // Redirect based on user type
            if (responseData.user_type === 1) {
                const routePath = this.$cookie.get('routePath')
                this.$router.push(
                    routePath && routePath !== '/login' ? routePath : '/'
                )
            } else {
                await dispatch('settings/redirectToHasPermissionRoute', true, {
                    root: true,
                })
            }
        } catch (error) {
            commit('setGoogleAuth', null)
        } finally {
            commit('setLoading', false)
        }
    },
    async verifyGoogle({ commit, rootGetters, dispatch }, form) {
        try {
            if (!form || Object.keys(form).length === 0) {
                commit('setGoogleAuth', null)
                return
            }
            commit('setLoading', true)

            const response = await this.$axios.post(`auth.verify`, form, {
                headers: {
                    Authorization: 'Bearer ' + this.$cookie.get('sub_key'),
                },
            })
            const responseData = response.data
            const token = response.headers.token
            responseData.token = token
            const cookieExpiration = 60 * 60 * 24 - 60 // 23 hours 59 minutes

            commit('setLogin', responseData)
            this.$toast.success(
                rootGetters['master/storeTl']('loggedInWelcomeBoard')
            )

            // Local storing
            this.$cookie.set('token', responseData.token, {
                path: '/',
                maxAge: cookieExpiration,
            })
            this.$cookie.set('user', responseData, {
                path: '/',
                maxAge: cookieExpiration,
            })

            // set currency
            if (responseData.currency)
                this.$cookie.set('currency', responseData.currency)
            else {
                // Setting default currency before logging in
                await dispatch('settings/currency', {}, { root: true })
            }

            // Store markup values
            ;['markup_1', 'markup_2'].forEach((key) => {
                if (responseData[key] !== undefined) {
                    this.$cookie.set(key, responseData[key], {
                        path: '/',
                        maxAge: cookieExpiration,
                    })
                }
            })
            this.$cookie.remove('sub_key')
            // set locale by user default
            if (responseData.language) {
                this.$cookie.set('locale', responseData.language)
            } else {
                this.$cookie.set('locale', 'th-TH')
            }

            if (responseData.user_type === 1) {
                const routePath = this.$cookie.get('routePath')
                this.$router.push(
                    routePath && routePath !== '/login' ? routePath : '/'
                )
            } else {
                await dispatch('settings/redirectToHasPermissionRoute', true, {
                    root: true,
                })
            }

            commit('setLoading', false)
            return true
        } catch (error) {
            commit('setLoading', false)
            return false
        } finally {
            commit('setLoading', false)
        }
    },

    async logout({ rootGetters }) {
        try {
            if (this.$cookie.get('token')) {
                await this.$axios.get('auth.logout')
            }
        } catch (error) {
            // Handle error if needed
        } finally {
            const cookiesToRemove = [
                'token',
                'permissionCode',
                'routePath',
                'ia',
                'user',
                'markup_1',
                'markup_2',
                'currency',
            ]
            cookiesToRemove.forEach((cookie) => this.$cookie.remove(cookie))
            this.$router.push('/login')
        }
    },
}

// Mutations
export const mutations = {
    setLogin(state, response) {
        state.user = response
    },
    setLoading(state, type) {
        state.loading = type
    },
    setGoogleAuth(state, data) {
        state.googleAuth = data
    },
}
